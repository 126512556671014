/*------------text Button------------*/

.btn {
	font-size: 1em;
	padding: 5px 10px;
	color: var(--clr-color-inverted);
	background-color: var(--clr-bg-inverted);
	border: none;
	box-shadow: var(--shadow);
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	align-content: center;
	font-family: Arial;
	text-decoration: none;
	cursor: pointer;
	line-height: 1em;
}

.btn:hover {
	box-shadow: var(--shadow-hover);
	transform: translate(0, -1px);
}
.btn[selected] {
	outline: medium solid var(--clr-yellow);
	outline-offset: 3px;
}

.btn:active {
	box-shadow: none;
	transform: translate(0, 3px);
}

/* .btn i {
  font-size: 0.8em;
} */

/*------------Intel List Button------------*/

.btn.to-intel {
	position: relative;
	padding-left: 30px;
}

.btn.to-intel::before,
.btn.to-intel::after {
	content: '';
	width: 30px;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	background-size: calc(contain - 5px);
	background-repeat: no-repeat;
	background-position: center center;
}

.btn.to-intel::after {
	left: 30px;
}

.btn.to-intel[faction='Requiem']::before {
	background-color: var(--clr-blue);
}

.btn.to-intel[faction='Omega']::before {
	background-color: var(--clr-red);
}

.btn.to-intel[faction='Maxis']::before {
	background-color: var(--clr-blue-d);
}

.btn.to-intel[faction='Dark Aether']::before {
	background-color: var(--clr-purple);
}

.btn.to-intel[collected]::after {
	content: ' ';
	left: unset;
	right: 0;
	background: linear-gradient(
		225deg,
		rgba(255, 255, 255, 0) 15%,
		var(--clr-green) 15%,
		var(--clr-green) 35%,
		rgba(255, 255, 255, 0) 16%
	);
}

.btn.to-intel[type='Documents']::before {
	background-image: url('../assets/img/icons/type/documents.png');
}

.btn.to-intel[type='Artifacts']::before {
	background-image: url('../assets/img/icons/type/artifacts.png');
}

.btn.to-intel[type='Audio Logs']::before {
	background-image: url('../assets/img/icons/type/audio\ logs.png');
}

.btn.to-intel[type='Radio Transmissions']::before {
	background-image: url('../assets/img/icons/type/radio\ transmissions.png');
}

/*------------UI Button------------*/

.btn.ui {
	--width: 50px;
	border-radius: 10px;
	border: none;
	width: var(--width);
	height: var(--width);
	padding: 5px 0;
	pointer-events: auto;
}

.btn.ui:not(.zoom-) {
	margin: 2px;
}

.btn.ui svg {
	font-size: calc(var(--width) / 1.6);
	margin: auto;
}

#discord {
	background-color: #5865f2;
	color: var(--clr-white-d);
}

#github {
	background-color: var(--clr-black);
	color: var(--clr-white);
}

#youtube {
	background-color: var(--clr-red);
	color: var(--clr-white);
}

#twitter {
	background-color: var(--clr-blue);
	color: var(--clr-white);
}

#instagram {
	background-color: var(--clr-pink);
	color: var(--clr-white);
}

#tiktok {
	background-color: var(--clr-black);
	color: var(--clr-white);
}

#snapchat {
	background-color: var(--clr-yellow);
	color: var(--clr-white);
}

/* zoom buttons */
.zoom-container {
	display: flex;
	flex-direction: column;
}
.zoom-.btn {
	border-radius: 0;
}

.zoom-container :first-child {
	border-top-left-radius: calc(var(--width) / 5);
	border-top-right-radius: calc(var(--width) / 5);
}
.zoom-container :last-child {
	border-bottom-left-radius: calc(var(--width) / 5);
	border-bottom-right-radius: calc(var(--width) / 5);
}
.zoom-.btn {
	--width: calc(50px / 1.4);
}

/*------------Checkbox-slider------------*/

.chk-btn {
	display: flex;
	flex-direction: row !important;
	align-content: center;
	justify-content: space-between;
	align-items: center;
}

.chk-btn p {
	display: inline;
}

.toggle {
	-webkit-appearance: none;
	appearance: none;
	border-radius: 0;
	position: relative;
	outline: none;
	width: 50px;
	height: 30px;
	background-color: var(--clr-red);
	border-radius: 50px;
	box-shadow: var(--shadow);
}

.toggle::after {
	content: '';
	position: absolute;
	top: 3px;
	left: 3px;
	width: 24px;
	height: 24px;
	background-color: var(--clr-grey-l);
	border-radius: 50%;
	transition: 100ms;
}

.toggle:checked {
	background-color: var(--clr-green);
}

.toggle.grey:checked {
	background-color: var(--clr-grey);
}

.toggle:hover::after {
	top: 1px;
	box-shadow: var(--shadow);
}

.toggle:checked::after {
	left: 24px;
	transition: 100ms;
}

.check {
	-webkit-appearance: none;
	appearance: none;
	border-radius: 0;
	font-size: 1em;
	position: relative;
	display: inline-flex;
	outline: none;
	width: 20px;
	height: 20px;
	background-color: var(--clr-bg-inverted);
	border-radius: 50px;
	box-shadow: var(--shadow);
	align-content: center;
	justify-content: center;
	align-items: center;
}

.check:checked {
	background-color: var(--clr-blue);
}

.check:checked::after {
	height: 15px;
	width: 7px;
	content: ' ';
	border-style: none solid solid none;
	border-width: thick;
	border-color: var(--clr-color-inverted);
	transform: rotate(45deg);
	/* margin-top: -3px; */
}

.check:hover::after {
	height: 15px;
	width: 7px;
	content: ' ';
	border-style: none solid solid none;
	border-width: thick;
	border-color: var(--clr-color-inverted);
	transform: rotate(45deg);
	/* margin-top: -3px; */
}

/* ----------Category btn--------------- */

.type {
	position: relative;
	background-blend-mode: normal;
	background-size: 150px;
	background-repeat: no-repeat;
	background-position: center;
	width: 200px;
	height: 120px;
	margin-bottom: 10px;
}

.type::before {
	content: attr(type);
	text-transform: uppercase;
	font-weight: 700;
	font-size: 0.7rem;
	display: block;
	background-color: var(--clr-bg);
	color: var(--clr-color);
	margin-left: -10px;
	margin-top: -60px;
	padding: 0px 10px 4px 10px;
}

.type::after {
	color: var(--clr-black);
	content: attr(value);
	bottom: 0px;
	left: 0;
	font-size: 0.8rem;
	font-weight: 500;
	font-family: serif;
	width: calc(100% - 5px);
	text-align: left;
	background-color: #ffffff8a;
	opacity: 100;
	position: absolute;
	padding-left: 5px;
}

/* ----------Type btn--------------- */

/* .type[type="Documents"] {
  background-image: url("../assets/img/intelTypes/documents.png");
}

.type[type="Audio logs"] {
  background-image: url("../assets/img/intelTypes/audio-logs.png");
}

.type[type="Radio transmissions"] {
  background-image: url("../assets/img/intelTypes/radio-logs.png");
}

.type[type="Artifacts"] {
  background-image: url("../assets/img/intelTypes/artifacts.png");
} */

.type-check {
	display: none;
}

.type-check:checked + label {
	outline: 3px solid var(--clr-yellow);
	outline-offset: 2px;
}

input[type='search'] {
	font-size: 1em;
	width: 100%;
	padding: 5px 10px;
}

/* inverted */

.btn.inverted {
	color: var(--clr-color);
	background-color: var(--clr-bg);
}

.droopy {
	width: 67.5%;
	height: 1.6rem;
	line-height: 1.6rem;
	background-color: var(--clr-white);
	color: var(--clr-grey-d);
	position: relative;
	border: 1px solid var(--clr-black);
}

.droopy > legend {
	visibility: hidden;
	width: 0px;
	height: 1px;
	transition: 0.3s;
}

.droopy:hover > legend .droopy:focus-within legend,
.droopy.filled legend {
	width: 75px;
}

.droopy .title {
	color: var(--clr-black);
	position: relative;
	top: 0px;
	padding-inline: 5px;
	transition: 0.3s;
	background-color: var(--clr-white);
	/* border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
}

.droopy:hover .title,
.droopy:focus-within .title,
.droopy.filled .title {
	top: -1rem;
	font-size: smaller;
}

.droopy > ul {
	position: relative;
	display: none;
}

.droopy:hover > ul {
	display: block;
	margin-top: -3px;
	z-index: var(--z-index-ui);
	width: 100%;
	padding: 5px;
	background-color: var(--clr-grey);
}

.droopy li {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.droopy li label {
	color: var(--clr-white-d);
	width: 100%;
}

.droopy li:hover {
	background-color: var(--clr-blue);
}

.droopy .list-selection {
	position: relative;
	display: none;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.droopy.filled .list-selection {
	position: absolute;
	display: inline-block;
	left: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.droopy .list-selection span {
	white-space: nowrap;
	border: 1px solid var(--clr-yellow);
	border-radius: 2px;
	padding: 2px;
	font-size: 0.7rem;
}

#curr-map-filter-label,
#show-collected-filter-label {
	color: var(--clr-color);
}

#curr-map-filter,
#show-collected-filter {
	margin-left: 10px;
}

.-small.toggle {
	background-color: var(--clr-grey);
	width: 40px;
	height: 25px;
}

.-small.toggle:checked {
	background-color: var(--clr-yellow);
}

.-small.toggle::after {
	top: 2.5px;
	left: 2px;
	width: 19px;
	height: 19px;
	background-color: var(--clr-grey-l);
	border-radius: 50%;
	transition: 100ms;
}

.-small.toggle:checked::after {
	left: 19px;
}

.-small.toggle:hover::after {
	top: 0px;
}
