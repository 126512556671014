* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: sans-serif;
}

html {
	font-size: x-large;
}

body {
	/* whites and blacks */
	--clr-white: #ffffff;
	--clr-white-d: #e3ddd9;
	--clr-white-extra-dark: #d3ceca;
	--clr-grey-l: #6a6a6b;
	--clr-grey: #565555;
	--clr-grey-d: #3c3c3b;
	--clr-black: #1f2223;
	--clr-black-50: #1f222363;
	/*faction colors*/
	--clr-blue: #50809c;
	--clr-blue-d: #252d5c;
	--clr-purple: #472359;
	--clr-red: #b33536;
	/* additional colors */
	--clr-green: #30883c;
	--clr-yellow: #d3a92b;
	--clr-orange: #d3642b;
	--clr-pink: #cc358b;
	/* backgrounds */
	--clr-bg: var(--clr-white-d);
	--clr-bg-lighter: #50504f;
	--clr-bg-inverted: var(--clr-grey-d);
	/* text color */
	--clr-color: var(--clr-grey-d);
	--clr-color-inverted: var(--clr-white-d);
	/* border properties*/
	--shadow: 0px 3px 0px 0px var(--clr-black);
	--shadow-hover: 0px 4px 0px 0px var(--clr-black);
	--modal-col-1: 7/16;
	--modal-col-2: 7/8;
	--modal-col-3: 14/8;
	--z-index-ui: 1050;
	--z-index-menu: 800;
	--z-index-map: 0;
	--radius: 10px;
	/* whites and blacks */
	--svg-border: var(--clr-red);
	--svg-roads: var(--clr-grey-l);
	--svg-background: var(--clr-grey);
	--svg-field: var(--clr-white-d);
	--svg-features: var(--clr-grey);
	--svg-buildings: var(--clr-grey-d);

	--fs-sm: clamp(0.8rem, 0.17vi + 0.76rem, 0.89rem);
	--fs-base: clamp(1rem, 0.34vi + 0.91rem, 1.19rem);
	--fs-md: clamp(1.25rem, 0.61vi + 1.1rem, 1.58rem);
	--fs-lg: clamp(1.56rem, 1vi + 1.31rem, 2.11rem);
	--fs-xl: clamp(1.95rem, 1.56vi + 1.56rem, 2.81rem);
	--fs-xxl: clamp(2.44rem, 2.38vi + 1.85rem, 3.75rem);
	--fs-xxxl: clamp(3.05rem, 3.54vi + 2.17rem, 5rem);
}

body.dark {
	--clr-bg: var(--clr-grey-d);
	--clr-bg-inverted: var(--clr-white-d);
	--clr-color: var(--clr-white-d);
	--clr-color-inverted: var(--clr-grey-d);

	/* whites and blacks */
	--svg-border: var(--clr-red);
	--svg-roads: var(--clr-black);
	--svg-background: #363635;
	--svg-field: var(--clr-grey);
	--svg-features: var(--clr-grey-l);
	--svg-buildings: var(--clr-white);
}

body.custom-color {
	--svg-border: var(--clr-green);
	--svg-roads: var(--clr-purple);
	--svg-background: var(--clr-yellow);
	--svg-field: var(--clr-white-d);
	--svg-features: #a16767;
	--svg-buildings: #38c715;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: var(--clr-white);
}

::-webkit-scrollbar-thumb {
	background: var(--clr-white-d);
}

::-webkit-scrollbar-thumb:hover {
	background: var(--clr-grey-d);
}

#filtered-intel::-webkit-scrollbar {
	width: 10px;
	margin-left: 5px;
}

#filtered-intel::-webkit-scrollbar-track {
	background: var(--clr-bg);
}

#filtered-intel::-webkit-scrollbar-thumb {
	background: var(--clr-bg-inverted);
}

#filtered-intel::-webkit-scrollbar-thumb:hover {
	background: var(--clr-grey);
}

.-hidden {
	display: none !important;
}

.collected-marker {
	opacity: 0.35;
}

.contributing {
	cursor: crosshair !important;
}