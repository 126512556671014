.leaflet-popup {
	border-radius: 12px !important;
}

.leaflet-popup-content-wrapper {
	border-radius: unset;
	background-color: var(--clr-bg-inverted) !important;
	border: none;
	padding: 0px !important;
}
.leaflet-popup-content {
	width: unset !important;
}

.intel-content {
	width: 300px;
	display: flex;
	flex-direction: column;
}
@media (min-width: 600px) {
	.intel-content {
		width: 600px;
	}
}

.leaflet-popup-content h1 {
	background-color: var(--clr-grey-d);
	padding: 5px 20px;
	width: 100%;
	color: var(--clr-white-d);
	text-transform: uppercase;
}
/* .leaflet-popup-content > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
} */
/* .leaflet-popup-content > div > div {
  display: flex;
  width: 300px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
} */
/* .leaflet-popup-content > div > img {
  width: 300px;
} */
/* .leaflet-popup-content p {
  padding: 5px 20px;
} */
.leaflet-popup-content {
	margin: unset;
}

.leaflet-popup-tip {
	background-color: var(--clr-bg-inverted) !important;
	z-index: -1;
}

/* .buttonContainer {
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 5px;
} */
.intel-icon {
	background: none;
	border: none;
}
.intel-icon > div {
	display: flex;
	justify-content: center;
}
.intel-icon .icon,
.intel-icon .background {
	position: absolute;
}
.intel-icon .icon {
	z-index: 2;
	top: 4px;
	left: calc(50% - 11.5px);
}
.intel-icon .background {
	z-index: 1;
}

.leaflet-control-attribution.leaflet-control {
	visibility: hidden;
}

.leaflet-right .leaflet-control {
	margin-right: 20px !important;
}

.leaflet-bottom .leaflet-control {
	margin-bottom: -3px !important;
}

.leaflet-top,
.leaflet-bottom {
	z-index: 1100 !important;
}
.leaflet-control-layers.leaflet-control {
	background-color: var(--clr-bg-inverted) !important;
	border-radius: 10px !important;
	/* box-shadow: var(--shadow) !important; TODO get this right */
}
.leaflet-control-layers.leaflet-control label {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

.leaflet-container .leaflet-control-layers-toggle {
	background-color: var(--clr-grey-d) !important;
	width: 50px !important;
	height: 50px !important;
	border-radius: 10px !important;
	background-image: unset !important;
	background-size: 32px 32px !important;
	/* color: var(--clr-color-inverted) !important; */

	-webkit-mask-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='layer-group' class='svg-inline--fa fa-layer-group ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M232.5 5.171C247.4-1.718 264.6-1.718 279.5 5.171L498.1 106.2C506.6 110.1 512 118.6 512 127.1C512 137.3 506.6 145.8 498.1 149.8L279.5 250.8C264.6 257.7 247.4 257.7 232.5 250.8L13.93 149.8C5.438 145.8 0 137.3 0 127.1C0 118.6 5.437 110.1 13.93 106.2L232.5 5.171zM498.1 234.2C506.6 238.1 512 246.6 512 255.1C512 265.3 506.6 273.8 498.1 277.8L279.5 378.8C264.6 385.7 247.4 385.7 232.5 378.8L13.93 277.8C5.438 273.8 0 265.3 0 255.1C0 246.6 5.437 238.1 13.93 234.2L67.13 209.6L219.1 279.8C242.5 290.7 269.5 290.7 292.9 279.8L444.9 209.6L498.1 234.2zM292.9 407.8L444.9 337.6L498.1 362.2C506.6 366.1 512 374.6 512 383.1C512 393.3 506.6 401.8 498.1 405.8L279.5 506.8C264.6 513.7 247.4 513.7 232.5 506.8L13.93 405.8C5.438 401.8 0 393.3 0 383.1C0 374.6 5.437 366.1 13.93 362.2L67.13 337.6L219.1 407.8C242.5 418.7 269.5 418.7 292.9 407.8V407.8z'%3E%3C/path%3E%3C/svg%3E") !important;
	mask-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='layer-group' class='svg-inline--fa fa-layer-group ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M232.5 5.171C247.4-1.718 264.6-1.718 279.5 5.171L498.1 106.2C506.6 110.1 512 118.6 512 127.1C512 137.3 506.6 145.8 498.1 149.8L279.5 250.8C264.6 257.7 247.4 257.7 232.5 250.8L13.93 149.8C5.438 145.8 0 137.3 0 127.1C0 118.6 5.437 110.1 13.93 106.2L232.5 5.171zM498.1 234.2C506.6 238.1 512 246.6 512 255.1C512 265.3 506.6 273.8 498.1 277.8L279.5 378.8C264.6 385.7 247.4 385.7 232.5 378.8L13.93 277.8C5.438 273.8 0 265.3 0 255.1C0 246.6 5.437 238.1 13.93 234.2L67.13 209.6L219.1 279.8C242.5 290.7 269.5 290.7 292.9 279.8L444.9 209.6L498.1 234.2zM292.9 407.8L444.9 337.6L498.1 362.2C506.6 366.1 512 374.6 512 383.1C512 393.3 506.6 401.8 498.1 405.8L279.5 506.8C264.6 513.7 247.4 513.7 232.5 506.8L13.93 405.8C5.438 401.8 0 393.3 0 383.1C0 374.6 5.437 366.1 13.93 362.2L67.13 337.6L219.1 407.8C242.5 418.7 269.5 418.7 292.9 407.8V407.8z'%3E%3C/path%3E%3C/svg%3E") !important;
	mask-size: 32px 32px !important;
	mask-repeat: no-repeat;
	mask-position: center;
}

.leaflet-container .leaflet-control-layers-list {
	accent-color: var(--clr-red) !important;
}

.leaflet-container a {
	color: var(--clr-color-inverted);
}

.leaflet-tile {
	border: solid black 5px !important;
}
