.cc-card {
	position: relative;
	border-width: 3px;
	border-style: solid;
	border-color: var(--clr-grey-l);
	background-color: var(--clr-grey-d);
}
.cc-card:hover {
	border-color: var(--clr-white-d);
}
.cc-card[complete] {
	border-color: var(--clr-green);
}
.cc-card > img {
	width: 100%;
}
.cc-card > h2 {
	display: block;
	background-color: var(--clr-black);
	padding: 3px 10px;
	width: fit-content;
	text-transform: uppercase;
	word-wrap: break-word;
	color: var(--clr-white);
	font-family: sans-serif;
	font-size: 1rem;
	font-weight: normal;
}
.cc-card > p {
	padding: 15px 10px;
	color: var(--clr-white);
	font-size: 0.8rem;
	font-weight: 100;
}
.cc-card .pin:hover {
	transition: all 0.3sec ease;
	color: var(--clr-yellow);
}
.cc-card .pin.rotate {
	color: var(--clr-yellow);
}
.cc-card .pin.rotate:hover {
	color: var(--clr-white);
}
.cc-card .card-btn-container {
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	display: flex;
	justify-content: space-between;
}
.cc-card .pin,
.cc-card .complete-card {
	border: unset;
	background: unset;
	color: white;
}
/*add card*/
.cc-add {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-style: dashed;
	border-spacing: 20px;
	text-decoration: none;
	color: var(--clr-grey-l);
	aspect-ratio: 900 / 240;
}
.cc-add:hover {
	color: var(--clr-white-d);
}
.cc-add > button {
	display: block;
	margin: auto;
	border: unset;
	background-color: unset;
	text-align: center;
	color: inherit;
	font-size: calc(var(--add-width) / 8);
}
.cc-add > p {
	display: block;
}

.cc-card button {
	cursor: pointer;
}
