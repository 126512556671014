@media only screen and (orientation: landscape) {
	body {
		flex-direction: row;
	}

	aside {
		position: sticky;
		top: 0;
		bottom: 0;
		overflow: auto;
	}

	aside[empty] .cc-add {
		display: none;
	}

	aside,
	main {
		flex: 0 0 50vw;
	}

	#mastery-progress {
		position: relative;
	}

	.title,
	.cc-card > h2 {
		font-size: 1rem;
	}
}

@media only screen and (min-width: 700px) {
	body {
		flex-direction: row;
	}

	aside {
		position: sticky;
		top: 0;
		bottom: 0;
		flex: 1 0 calc(100% / 2 * 1);
		overflow: auto;
	}

	aside[empty] .cc-add {
		display: none;
	}

	main {
		flex: 1 0 calc(100% / 2 * 1);
	}

	#card-container {
		flex-direction: row;
		flex-wrap: wrap;
		/* width: 100%; */
	}

	#mastery-progress {
		position: sticky;
		justify-content: space-between;
	}
	.cc-card {
		flex-basis: calc(100%);
		flex-shrink: 1;
	}
}

@media only screen and (min-width: 700px) and (orientation: landscape) {
	#mastery-progress {
		display: flex;
		position: sticky;
	}
}
@media only screen and (min-width: 1024px) {
	aside {
		flex: 1 0 calc(100% / 3 * 1);
		max-width: 600px;
	}
	.cc-card {
		flex-basis: calc(100% / 2 - 10px);
		flex-shrink: 1;
	}
	main {
		flex: 1 0 calc(100% / 3 * 2);
		max-width: 800px;
		margin: auto;
	}
}
@media only screen and (min-width: 1400px) {
	#pinned-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	main {
		flex: 1 0 calc(100% / 3 * 2);
		max-width: 1200px;
		margin: auto;
	}
}
