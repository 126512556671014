@import url('global.css');
@import url('buttons.css');

html,
body {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	overflow-x: hidden;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* Dropdown Content (Hidden by Default) */

.dropdown-content {
	display: none;
	position: absolute;
	background-color: var(--clr-grey);
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px var(--clr-black-50);
	z-index: var(--z-index-menu);
}

/* Show the dropdown menu on hover */

.dropdown:hover .dropdown-content {
	display: block;
}

.current-map {
	background-color: var(--clr-white-d);
	color: var(--clr-grey);
}

/* ----------Modal Syle--------------- */

.modal-bg {
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--clr-black-50);
	width: 100vw;
	height: 100vh;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: var(--z-index-ui);
}

.modal {
	display: flex;
	background-color: var(--clr-bg);
	aspect-ratio: var(--modal-col-1);
	width: 300px;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	user-select: none;
	justify-content: space-evenly;
}

.modal.-big {
	aspect-ratio: var(--modal-col-2);
	width: 600px;
}

select.faction-list {
	background: unset;
	border: unset;
	font-size: 1.5em;
	text-transform: uppercase;
	font-weight: bold;
	color: var(--clr-color);
	cursor: pointer;
}

select.faction-list > option {
	background: var(--clr-bg-inverted);
	color: var(--clr-color-inverted);
	border: unset;
}

select.faction-list > option:hover {
	background-color: var(--clr-red);
}

.modal #filtered-intel,
.button-list,
#intel-filters #intel-type-select {
	display: flex;
	margin: 0 auto;
	flex-wrap: nowrap;
	flex-direction: column;
	width: inherit;
	align-items: center;
	z-index: 1;
}

.button-list {
	width: -webkit-fill-available;
}

.button-list a {
	margin-bottom: 10px;
}

#filtered-intel {
	aspect-ratio: var(--modal-col-1);
	overflow-y: scroll;
	overflow-x: hidden;
	padding-right: 5px;
	height: 80%;
	padding: 10px;
}

#intel-list #filtered-intel button.btn,
.settings > div > .btn {
	margin-bottom: 15px;
	flex-direction: column;
	width: 100%;
}

.modal .sub-modal {
	background-color: var(--clr-bg-inverted);
	width: calc(100% - 50px);
	height: calc(100% - 50px);
	flex: unset;
	text-align: center;
}

.sub-modal h2 {
	margin-top: 3vh;
	text-align: center;
}

.sub-modal button {
	float: right;
}

.sub-modal img {
	width: inherit;
	padding: inherit;
}

.faction-list-icon {
	height: 40px;
}

.submodal-list {
	display: inline-block;
	list-style: none;
}

.submodal-list li {
	height: 40px;
	display: flex;
	align-items: center;
	margin: 5px;
}

#search-form {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	padding: 5px;
	align-items: center;
}

#search-form .btn {
	height: 35px;
}

#totals {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
}

#description {
	padding: 10px;
	user-select: text;
}

.settings {
	color: var(--clr-color);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 15px;
}

.settings > h2,
.settings > .button-list > p {
	margin-bottom: 5px;
}

.settings > div {
	display: flex;
}

/*------------Donut Graph------------*/

.graph {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.graph > svg > path {
	transition: 100ms;
}

.graph > svg > path:hover {
	stroke-width: 30px;
}

.chart-donut {
	transform: matrix(0 -1 -1 0 0 0);
}

/*notification styling */

#notification-popup {
	display: none;
	position: absolute;
	bottom: -200px;
	background-color: rgba(0, 0, 0, 0.404);
	color: white;
	border-radius: 50px;
	padding: 10px 15px;
	/* transition: bottom 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86); */
	margin: auto 0;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 99;
}

#notification-popup.animated {
	animation: bounce-in 3s;
	display: block;
}

#notification-popup.fixed {
	bottom: 0px;
	display: block;
}

@keyframes bounce-in {
	0% {
		bottom: -20px;
	}

	1% {
		bottom: 0px;
	}

	3% {
		bottom: -12px;
	}

	6% {
		bottom: -20px;
	}

	9% {
		bottom: -5px;
	}

	10% {
		bottom: 0px;
	}

	100% {
		bottom: 0px;
	}
}

#error-notif {
	display: none;
}

/* #lightbox {
  max-width: 100%;
  max-height: 100%;
}

#lightbox-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-ui);
} */

#settings-desc #import-export {
	height: 10vh;
	width: -webkit-fill-available;
	min-width: -webkit-fill-available;
	max-width: -webkit-fill-available;
	max-height: 450px;
}

#settings-desc {
	padding: 10px;
	display: block;
	height: auto;
	width: fit-content;
}

#settings-desc p {
	color: var(--clr-color-inverted);
	padding-top: 5px;
	padding-bottom: 5px;
}

#more-options {
	display: flex;
	gap: 5px;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	height: auto;
	padding: 5px;
	font-size: small;
}

#more-options button {
	display: block;
	flex: 0 1 calc(50% - 5px);
}
#more-options button i {
	margin-left: 5px;
}
#more-options button i.fa-check-square {
	color: var(--clr-green);
}
#more-options button i.fa-ban {
	color: var(--clr-red);
}

html,
body {
	margin: 0;
	padding: 0;
	overflow-x: hidden; /* Prevents horizontal overflow */
}
